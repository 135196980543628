import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = props => {
  const { icon, iconStyle = 'fas', size = 'xl', colour, link, route, onClick } = props;
  const element = <FontAwesomeIcon icon={[iconStyle, icon]} size={size} color={colour} />;
  let classes = ['icon'];
  if (onClick) classes.push('pointer');

  if (route) {
    return <NavLink to={route}>{element}</NavLink>;
  } else {
    return <a className={classes.join(' ')} href={link} onClick={onClick}>{element}</a>;
  }
}

export default Icon;
