import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Component from './components/Component';
import About from './pages/About';
import Home from './pages/Home';
import Countdown from './pages/Countdown';
import Demos from './pages/Demos';
import Privacy from './pages/Privacy';
import NoPage from './pages/NoPage';
import { initSettings } from './actions/SettingsActions';

class Pages extends Component {
  componentDidMount = () => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      this.props.initSettings(settings);
      this.context.changeTheme(settings.theme);
    }
  }

  componentDidUpdate = () => {
    if (this.context.theme === 'dark') {
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />}>
            <Route path=":appName" element={<Privacy />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="/petals-countdown" element={<Countdown />} />
          <Route path="/demos" element={<Demos />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ initSettings }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Pages);

