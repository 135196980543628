import Gallery from "./Gallery";
import ballSortImg from '../assets/images/ball-sort.png';
import learnTamilImg from '../assets/images/learn-tamil.png';

const apps = [
  {
    name: "Learn Tamil",
    image: learnTamilImg,
    info: "Learn everyday phrases and words and challenge yourself with fun quizzes.",
    playId: "com.learntamil",
    route: "learn-tamil",
  },
  {
    name: "Ball Sort Puzzle",
    image: ballSortImg,
    info: "Fun, free to play, puzzle game without ads.",
    playId: "com.cc.ballsort",
    route: "ball-sort",
  },
];

const AppGallery = props => {
  const { mode = "route", title } = props;
  return (
    <Gallery mode={mode} title={title} apps={apps} />
  )
}

export default AppGallery;