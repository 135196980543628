import { useParams } from "react-router";

import privacyPolicies from '../assets/docs/privacy-policies.json';
import Container from '../components/Container';
import AppGallery from '../components/AppGallery';
import Markdown from '../components/Markdown';
import Error from '../components/Error';

const Privacy = () => {
  const { appName } = useParams();
  let children, margin = true;
  if (!appName) {
    margin = false;
    const text = `
Cerebral Cereal are committed to building apps that are free to use and collect as little data about you as possible.
See below for the specific privacy policies of our apps.
    `
    children = (
      <>
        <Markdown children={text} />
        <AppGallery title="Privacy Policies" />
      </>
      
    );
  } else if (appName in privacyPolicies) {
    const policy = privacyPolicies[appName];
    const heading = policy.displayName + ' Privacy';
    children = (
      <Markdown children={policy.policy} title={heading} />
    )
  } else {
    const msg = "No policy found for " + appName;
    children = <Error code={404} msg={msg} />;
  }

  return (
    <Container page="Privacy" margin={margin}>{children}</Container>
  );
}

export default Privacy;