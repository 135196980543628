import ReactMarkdown from 'react-markdown'

const Markdown = props => {
  const { children, title } = props;

  return (
    <div className="article-parent">
      {title && <div className="h1">{title}</div>}
      <div className="article">
        <ReactMarkdown children={children} />
      </div>
    </div>
  )
}

export default Markdown;