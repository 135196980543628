import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";

import brain from '../assets/images/brain.svg';
import Component from './Component';
import Icon from './Icon';
import { saveSettings } from '../actions/SettingsActions';

class Header extends Component {
  toggleTheme = () => {
    const { settings, saveSettings } = this.props;
    const newTheme = settings.theme === 'dark' ? 'light' : 'dark';
    this.context.changeTheme(newTheme);
    saveSettings({ theme: newTheme });
  }

  render() {
    const { page, settings } = this.props;
    const link = [
      {name: "Home", route: "/"},
      {name: "Privacy", route: "/privacy"},
      {name: "About", route: "/about"},
    ];
    const icon = settings.theme === 'dark' ? 'sun' : 'moon';
    return (
      <div className="header">
        <div className="row-container">
          <div className="row a-center">
            <NavLink to="/" className="logo-parent">
              <img src={brain} className="logo" alt="brain" />
            </NavLink>
            <NavLink to="/" className="logo-text">
              <div className="h1">Cerebral Cereal</div>
            </NavLink>
          </div>
          <div className="row">
            {
              link.map((link, i) => {
                let className = "link";
                if (page === link.name) className += " selected";
                return <NavLink key={i} className={className} to={link.route} children={link.name} />
              })
            }
            <Icon icon={icon} size="lg" onClick={this.toggleTheme} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ saveSettings }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);

