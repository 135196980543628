import React, { Component } from 'react';

const DEFAULT_THEME = 'dark';
export const CommonContext = React.createContext('Some Default');

export class CommonProvider extends Component {
  static defaultProps = {
    theme: DEFAULT_THEME,
  }

  constructor(props) {
    super(props);
    this.state = {
      theme: props.theme,
    };
  }

  changeTheme = (theme, callback) => {
    if (theme !== this.state.theme) {
      this.setState({theme}, callback);
    } else if (callback) {
      callback();
    }
  }

  render() {
    const context = {
      theme: this.state.theme,
      changeTheme: this.changeTheme,
    };

    return (
      <CommonContext.Provider value={context}>
        {this.props.children}
      </CommonContext.Provider>
    );
  }
}