import React from 'react';
import Component from '../components/Component';
import Container from '../components/Container';
import DevGallery from '../components/DevGallery';

class Home extends Component {
  render() {
    return (
      <Container page="Demos" margin={false}>
        <DevGallery title="In Development" />
      </Container>
    );
  }
}

export default Home;
