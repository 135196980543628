import { useNavigate } from "react-router-dom";
import Icon from './Icon';

const Gallery = props => {
  const { mode = "route", title, apps } = props;
  const router = useNavigate();

  const getButtons = app => {
    if (mode === 'store') {
      return (
        <>
          {app.playId && <Icon icon="google-play" iconStyle="fab" />}
        </>
      )
    } else {  // mode == 'route'
      return <Icon icon={app.icon || 'link'} iconStyle={app.iconStyle || 'fas'} />;
    }
  }

  const navigate = app => {
    if (mode === 'store') {
      window.open('https://play.google.com/store/apps/details?id=' + app.playId,'_blank');
    } else if (app.url) {
      window.open(app.url, '_blank');
    } else {
      router(app.route);
    }
  }

  return (
    <div className="gallery ,b3">
      <div className="h1">{title}</div>
      {apps.map((app, i) => {
        return (
          <div className="row" key={i} onClick={() => navigate(app)}>
            <div className="content">
              <img src={app.image} alt={app.name} />
              <div className="text">
                <div className="appName">{app.name}</div>
                <div className="desc">{app.info}</div>
              </div>
            </div>
            <div className="toolbar">
              {getButtons(app)}
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default Gallery;