import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

import { CommonProvider } from "./contexts/CommonContext";
import Pages from './Pages';
import rootReducer from './reducers';
import './styles/main.css';

const App = () => {
  library.add(faLink, faMoon, faSun, faApple, faGooglePlay);
  const store = configureStore({reducer: rootReducer});
  return (
    <Provider store={store}>
      <CommonProvider>
        <Pages />
      </CommonProvider>
    </Provider>
  );
}

export default App;
